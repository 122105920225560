<div class="edit-container">
    <h3><label class="msi-label msi-margin-s">{{IsCreating ? "New Premise Hazard" : "Modifying Premise Hazard"}}</label></h3>
    <form [formGroup]="PremiseHazardForm" (ngSubmit)="onSubmit()" (disabled)="IsSubmitting" class="msi-margin-m">
        <div class="row">
            <div class="col">
                <label class="msi-label">Record Title</label> <input msiInput class="msi-margin-s" placeholder="Premise Hazard Name" formControlName="recordTitle" />
                <div *ngIf="!PremiseHazardForm.controls['recordTitle'].valid && PremiseHazardForm.controls['recordTitle'].touched">
                    <div style="color: red;">Field is required</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="msi-label">Priority</label>
                <msi-select placeholder="No Priority Set" formControlName="priority" class="text-input msi-margin-s">
                    <msi-select-option disabled="true">No Priority Set</msi-select-option>
                    <msi-select-option *ngFor="let priority of PRIORITY_TYPES" [value]="priority">{{priority | titlecase}}</msi-select-option>
                </msi-select>
                <div *ngIf="!PremiseHazardForm.controls['priority'].valid && PremiseHazardForm.controls['priority'].touched">
                    <div style="color: red;">Field is required</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="msi-label">Authorizing Personnel</label><input msiInput class="msi-margin-s" placeholder="Authorizing Personnel" formControlName="authorizingPersonnel" />
                <msi-checkbox [checked]="PremiseHazardForm.controls.isReviewRequired.value" formControlName="isReviewRequired">Further Review Required</msi-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label class="msi-label">Owner Information</label>
                <label class="msi-label">Name</label> <input msiInput class="msi-margin-s" placeholder="Owner Name" formControlName="ownerName" />
                <label class="msi-label">Phone</label> <input msiInput class="msi-margin-s" placeholder="Owner Phone" formControlName="ownerPhone" />
                <label class="msi-label">Address</label> <input msiInput class="msi-margin-s" placeholder="Owner Address" formControlName="ownerAddress" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label class="msi-label">
                    Temporary Hazard Configuration
                </label>
                <msi-checkbox label="Is Temporary" [checked]="PremiseHazardForm.controls.isTemporary.value" formControlName="isTemporary">Temporary Hazard</msi-checkbox>
                <div *ngIf="PremiseHazardForm.controls.isTemporary.value" class="msi-padding-m">
                    <label class="msi-label">Purge Date</label> <msi-calendar (dateChanged)="onPurgeDateChanged($event)" [allowEmpty]="true" [allowPastTime]="false" [allowFutureTime]="true" />
                    <label class="msi-label">Purge Type</label> <msi-select placeholder="No Purge Type Selected" formControlName="purgeType">
                        <msi-select-option *ngFor="let purgeType of PURGE_TYPES" [value]="purgeType">{{purgeType}}</msi-select-option>
                    </msi-select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label class="msi-label">
                    Premise Hazard Type <msi-icon name="ic_warning" color="#eed202" *ngIf="!PremiseHazardForm.controls.premiseHazardTypeId.value" msiTooltip="When not associated to a type, a Premise Hazard will not show up in Proximity queries, only Radius" />
                </label>
                <msi-select placeholder="No Type Assigned" formControlName="premiseHazardTypeId">
                    <msi-select-option *ngIf="PremiseHazardTypes.length !== 0">No Type Assigned</msi-select-option>
                    <msi-select-option *ngFor="let premiseHazardType of PremiseHazardTypes" [value]="premiseHazardType.id">{{premiseHazardType.code}}</msi-select-option>
                    <msi-select-option disabled="true" *ngIf="PremiseHazardTypes.length === 0">No Types Available</msi-select-option>
                </msi-select>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label class="msi-label">
                    Address <msi-icon name="ic_error" color="#cc0000" *ngIf="!PremiseHazardForm.controls.addressId.value && !isCreatingAddress" msiTooltip="A premise hazard must have an address" />
                </label>
                <div class="form-row" style="display: flex; flex-wrap: nowrap;">
                    <div class="col-8">
                        <div *ngIf="isCreatingAddress; then creatingAddressForm else selectingAddressForm"></div>
                        <ng-template #selectingAddressForm>
                            <msi-select placeholder="No Address Assigned" formControlName="addressId" style="color: var(--text-secondary)">
                                <msi-select-option disabled="true" *ngIf="Addresses.length !== 0" style="color: var(--text-secondary)">
                                    No Address Assigned
                                </msi-select-option>
                                <msi-select-option *ngFor="let address of Addresses" [value]="address.id">
                                    {{address.commonPlace}} {{address.houseAddress}} lon: {{address.longitude}}, lat: {{address.latitude}}
                                </msi-select-option>
                                <msi-select-option disabled="true" *ngIf="Addresses.length === 0">No Addresses Available</msi-select-option>
                            </msi-select>
                        </ng-template>
                        <ng-template #creatingAddressForm>
                            <label class="msi-label">Creating address: {{ selectedAddressCandidate?.matchLocation?.houseAddress }}</label>
                        </ng-template>
                    </div>
                    <div class="col-4">
                        <div *ngIf="isCreatingAddress; then warningClearAddress else createAddress" [ngModel]="isCreatingAddress"></div>
                        <ng-template #warningClearAddress>
                            <button type="button" msiTooltip="Remove the selected geocoded address" class="msi-btn msi-btn-text-and-icon msi-margin-left-s msi-margin-right-s msi-btn-secondary" (click)="openModal(removeAddressModal)">
                                <msi-icon name="ic_remove" class="delete-icon" />Change Address
                            </button>
                        </ng-template>
                        <ng-template #createAddress>
                            <button type="button" msiTooltip="Add a new address" class="msi-btn msi-btn-text-and-icon msi-margin-left-s msi-margin-right-s" (click)="openGeocodeModal()">
                                <msi-icon name="ic_add" />Add Address
                            </button>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label class="msi-label">Comments</label> <textarea msiInput class="msi-textarea" placeholder="Comments" formControlName="comments" style="margin-left: 20px; width: 70%;"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="msi-label">Contacts</label>
            </div>
        </div>
        <div *ngFor="let contact of ContactsToAdd; let contactIdx = index;">
            <div class="row row-with-cancel-icon msi-padding-s">
                <div class="col-1">
                    <button type="button" class="msi-btn msi-btn-icon" (click)="removeContact(contactIdx)"><msi-icon name="ic_remove" [size]="14" class="delete-icon" /></button>
                </div>
                <div class="col">
                    Contact {{ contactIdx + 1 }}
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <label class="msi-label">Name</label>
                </div>
                <div class="col msi-margin-s">
                    {{ contact.name }}
                </div>
            </div>
            <div *ngFor="let device of contact.devices; let deviceIdx = index;">
                <div class="form-row row-with-cancel-icon msi-margin-s">
                    <div class="col-1">
                        <button type="button" class="msi-btn msi-btn-icon" (click)="removeDeviceFromContact(contactIdx, deviceIdx)"><msi-icon name="ic_remove" [size]="14" class="delete-icon" /></button>
                    </div>
                    <div class="col">
                        Device {{ deviceIdx + 1 }}
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <label class="msi-label">Device Type</label>
                    </div>
                    <div class="col">
                        {{ device.deviceType | titlecase }}
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <label class="msi-label">Address</label>
                    </div>
                    <div class="col">
                        {{ device.address }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button type="button" msiTooltip="Add a new contact" class="msi-btn msi-btn-text-and-icon" style="margin:6px;" (click)="openContactModal()"><msi-icon name="ic_add" />Add Contact</button>
            </div>
        </div>
        <label class="msi-label">
            Attachments
        </label>
        <div>
            Attachments can be created or removed after the premise hazard is {{IsCreating ? "created" : "updated"}}.
        </div>
        <div class="form-row justify-content-between" style="display: flex; flex-wrap: nowrap;">
            <div class="col">
                <button class="msi-btn msi-btn-text-and-icon" type="submit" [disabled]="IsSubmitting">
                    <msi-spinner *ngIf="IsSubmitting" class="col-4" /><msi-icon name="ic_add" />{{ IsCreating ? "Add" : "Update"}} Premise Hazard
                </button>
            </div>
            <div class="col">
                <button type="button" class="msi-btn msi-btn-text-and-icon col-4" (click)="cancelClicked()" [disabled]="IsSubmitting">
                    <msi-icon name="ic_remove" class="delete-icon" />Cancel
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #contactFormModal let-form="ContactForm" let-ref="modalRef">
    <msi-modal [modalRef]="ref">
        <msi-modal-header>
            <label class="msi-label" style="align-content: center;">Add New Contact</label>
        </msi-modal-header>
        <form [formGroup]="ContactForm" class="msi-margin-m">
            <msi-modal-content>
                <label class="msi-label">Name</label>
                <input msiInput class="textInput" placeholder="Contact Name" formControlName="name" />
                <div formArrayName="devices" class="msi-margin-s">
                    <div *ngFor="let device of ContactForm.controls.devices.value; let deviceIdx = index; trackBy: trackByDeviceIndex">
                        <div [formGroupName]="deviceIdx">
                            <div class="row row-with-cancel-icon">
                                <div class="col-2">
                                    <button type="button" class="msi-btn msi-btn-icon" (click)="removeDeviceFromContactModal(deviceIdx)"><msi-icon name="ic_remove" [size]="14" class="delete-icon" /></button>
                                </div>
                                <div class="col">
                                    Device {{ deviceIdx + 1 }}
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <label class="msi-label">Device Type</label>
                                </div>
                                <div class="col">
                                    <msi-select placeholder="No Device Type Assigned" formControlName="deviceType">
                                        <msi-select-option disabled="true">No Device Type Assigned</msi-select-option>
                                        <msi-select-option *ngFor="let deviceType of DEVICE_TYPES" [value]="deviceType">{{deviceType | titlecase}}</msi-select-option>
                                    </msi-select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <label class="msi-label">Address</label>
                                </div>
                                <div class="col">
                                    <input msiInput class="msi-margin-bottom-1 text-input" placeholder="Address" formControlName="address" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="button" msiTooltip="Add a new device to this contact" class="msi-btn msi-btn-text-and-icon msi-margin-s" (click)="addDeviceToContactModal()"><msi-icon name="ic_add" />Add Device</button>
                    </div>
                </div>
            </msi-modal-content>
            <msi-modal-actions>
                <div class="form-row-bottom" style="justify-content: space-between;">
                    <div class="col">
                        <button type="button" class="msi-btn msi-btn-primary msi-btn-text-and-icon msi-margin-s" (click)="ref.close(true)" [disabled]="!ContactForm.valid">
                            <msi-icon name="ic_add" />Add Contact
                        </button>
                    </div>
                    <div class="col">
                        <button type="button" class="msi-btn msi-btn-primary msi-btn-text-and-icon msi-margin-s" (click)="ref.close(false)">
                            <msi-icon name="ic_remove" class="delete-icon" />Cancel
                        </button>
                    </div>
                </div>
            </msi-modal-actions>
        </form>
    </msi-modal>
</ng-template>

<ng-template #geocodeModal let-form="GeocodeForm" let-ref="modalRef">
    <msi-modal [modalRef]="ref">
        <msi-modal-header>
            <label class="msi-label">
                Lookup Address
            </label>
        </msi-modal-header>
        <form [formGroup]="GeocodeForm" class="msi-margin-m">
            <msi-modal-content class="msi-content-modal-item">
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Location Name</label>
                        <input class="msi-input" placeholder="Location Name" formControlName="locationName" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Address</label>
                        <input class="msi-input" placeholder="Street or full address" formControlName="address" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">City</label>
                        <input class="msi-input" placeholder="City" formControlName="city" />
                    </div>
                    <div class="col">
                        <label class="msi-label">Zip Code</label>
                        <input class="msi-input" placeholder="Zip Code" formControlName="zipCode" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Building</label>
                        <input class="msi-input" placeholder="Building" formControlName="building" />
                    </div>
                    <div class="col">
                        <label class="msi-label">Apartment/Unit Number</label>
                        <input class="msi-input" placeholder="Apartment/Unit Number" formControlName="apartment" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Floor</label>
                        <input class="msi-input" placeholder="Floor" formControlName="floor" />
                    </div>
                    <div class="col">
                        <label class="msi-label">Subdivision</label>
                        <input class="msi-input" placeholder="Subdivision" formControlName="subdivision" />
                    </div>
                </div>
            </msi-modal-content>
            <msi-modal-actions>
                <div *ngIf="geocodeProcessing" class="justify-content: center;">
                    <msi-spinner class="col" />
                </div>
                <div class="form-row msi-padding-s" style="width: 100%">
                    <div class="form-row-bottom" style="justify-content: space-between;">
                        <div class="col">
                            <button type="button" class="msi-btn msi-btn-text-and-icon" (click)="onSubmitGeocodeForm()" [disabled]="!GeocodeForm.valid">
                                <msi-icon name="ic_search" />Lookup Address
                            </button>
                        </div>
                        <div class="col">
                            <button type="button" class="msi-btn msi-btn-text-and-icon msi-btn-secondary" (click)="onCancelGeocodeForm()">
                                <msi-icon name="ic_remove" class="delete-icon" />Cancel
                            </button>
                        </div>
                    </div>

                </div>
            </msi-modal-actions>
        </form>
    </msi-modal>
</ng-template>

<ng-template #removeAddressModal let-data let-ref="modalRef">
    <msi-modal>
        <msi-modal-header class="msi-margin-s">
            <label class="msi-label">Remove Address</label>
        </msi-modal-header>
        <msi-modal-content class="msi-margin-m">
            <p>Are you sure you want to remove the selected address?</p>
        </msi-modal-content>
        <msi-modal-actions align="center" class="msi-margin-s">
            <button type="button" class="msi-btn msi-btn-primary msi-btn-text-and-icon" (click)="ref.close(false)">
                <msi-icon name="ic_remove" class="delete-icon"/>Cancel
            </button>
            <button type="button" class="msi-btn msi-btn-secondary msi-btn-text-and-icon" (click)="ref.close(true)" [disabled]="geocodeProcessing">
                <msi-icon name="ic_trash"/>Remove Address
            </button>
        </msi-modal-actions>
    </msi-modal>
</ng-template>

<ng-template #selectGeocodedAddress let-ref="modalRef">
    <msi-content-modal #modal [modalRef]="ref" [items]="matchedLocations">
        <msi-content-modal-title>
            <span class="msi-content-modal-title">Geocoded Results</span>
        </msi-content-modal-title>
        <div class="msi-content-modal-item">
            <div class="row">
                <div class="col">
                    <label class="msi-label">Distance</label>
                    {{ matchedLocations[modal.index].matchLocation.distance | number:'1.0-2' }} meters / {{ matchedLocations[modal.index].matchLocation.distance * 3.28084 | number:'1.0-3' }} feet
                </div>
                <div class="col">
                    <label class="msi-label">Match Strength</label>
                    {{ matchedLocations[modal.index].score }}
                </div>
                <div class="col">
                    <label class="msi-label">Location Type</label>
                    {{ matchedLocations[modal.index].matchLocation.locationMark | emptyText:'No information'}}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="msi-label">Location Name</label>
                    {{ matchedLocations[modal.index].matchLocation.commonPlace | emptyText:'No information' }}
                </div>
                <div class="col">
                    <label class="msi-label">Address</label>
                    {{ matchedLocations[modal.index].matchLocation.houseAddress | emptyText:'No information'}}
                </div>
                <div class="col">
                    <label class="msi-label">Intersection</label>
                    {{ matchedLocations[modal.index].matchLocation.intersection | emptyText:'No information' }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="msi-label">City</label>
                    {{ matchedLocations[modal.index].matchLocation.city | emptyText:'No information' }}
                </div>
                <div class="col">
                    <label class="msi-label">State</label>
                    {{ matchedLocations[modal.index].matchLocation.state | emptyText:'No information' }}
                </div>
                <div class="col">
                    <label class="msi-label">Zip Code</label>
                    {{ matchedLocations[modal.index].matchLocation.zipCode | emptyText:'No information' }}
                </div>
            </div>
            <div class="row ">
                <div class="col">
                    <label class="msi-label">Longitude</label>
                    {{ matchedLocations[modal.index].matchLocation.x | number:'1.0-6' }}
                </div>
                <div class="col">
                    <label class="msi-label">Latitude</label>
                    {{ matchedLocations[modal.index].matchLocation.y | number:'1.0-6'}}
                </div>
                <div class="col"></div>"
            </div>
            <div class="row" *ngIf="matchedLocations[modal.index].description">
                <div class="col">
                    <label class="msi-label">Description</label>
                    {{ matchedLocations[modal.index].matchLocation.description | emptyText:'No information' }}
                </div>
            </div>
            <div class="form-row" style="display: flex; flex-direction: row; justify-content:space-between;">
                <div class="col">
                    <button type="button" class="msi-btn msi-btn-text-and-icon msi-btn-primary" (click)="ref.close(modal.index)"><msi-icon name="ic_add" />Use this location</button>
                </div>
                <div class="col">
                    <button type="button" class="msi-btn msi-btn-text-and-icon msi-btn-primary" (click)="ref.close(-1)"><msi-icon name="ic_remove" class="delete-icon" />Close</button>
                </div>
            </div>
        </div>
    </msi-content-modal>
</ng-template>

<ng-template #loadingModal let-ref="modalRef" let-data>
    <msi-modal>
        <msi-modal-header class="msi-margin-s">
            <label class="msi-label">{{data.title}}</label>
        </msi-modal-header>
        <msi-modal-content class="msi-margin-m">
            <p>{{data.message}}</p>
            <msi-spinner size="large"/>
        </msi-modal-content>
    </msi-modal>
</ng-template>
