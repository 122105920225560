import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalService, ToastService } from "@msi/cobalt";
import { Subscription, catchError, of, take, throwError } from "rxjs";
import { PremiseHazardCommandsRestService } from "../../services/premise-hazard-command-rest.service";
import { PremiseHazardQueryRestService } from "../../services/premise-hazard-rest.service";
import { RestService } from "../../services/rest.service";
import { AttachmentInputModel } from "../attachment-input.model";
import { Attachment } from "../attachment.model";
import { PremiseHazard } from "../premise-hazard.model";

@Component({
  selector: 'app-view-premise-hazard-item',
  templateUrl: './premise-hazard-view.component.html',
  styleUrls: ['./premise-hazard-view.component.scss']
})

export class PremiseHazardViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() PremiseHazard: PremiseHazard;
  @ViewChild('attachmentModal') attachmentModal: TemplateRef<any>;
  @ViewChild('deleteAttachmentModal') deleteAttachmentModal: TemplateRef<any>;
  @ViewChild('loadingModal') loadingModal: TemplateRef<any>;

  @Output() premiseHazardModifiedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestProccessing: EventEmitter<void> = new EventEmitter<void>();
  @Output() requestCompleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() isSharedHazardEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  attachmentForm: FormGroup;
  private selectedFile: File;

  dateFormat: string = 'dd/MM/yyyy HH:mm a zzzz';

  public isSharedHazard: boolean = false;
  private ccCustomer: string = null;
  private ccAgency: string = null;

  private customerSubscription: Subscription;
  private agencySubscription: Subscription;

  constructor(private modalService: ModalService,
    private toastService: ToastService,
    private commandService: PremiseHazardCommandsRestService,
    private queryService: PremiseHazardQueryRestService,
    private legacyRestService: RestService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setPremiseHazard(this.PremiseHazard);
  }

  ngOnDestroy(): void {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.agencySubscription) {
      this.agencySubscription.unsubscribe
    }
  }

  ngOnInit(): void {
    this.customerSubscription = this.legacyRestService.currCustomer.subscribe(res => {
      this.ccCustomer = res;
    });
    this.agencySubscription = this.legacyRestService.currentAgency.subscribe(res => {
      this.ccAgency
    });
  }

  setPremiseHazard(premiseHazard: PremiseHazard): void {
    if (!this.PremiseHazard && !premiseHazard) {
      return;
    }
    this.PremiseHazard = premiseHazard;
    const customerEquals: boolean = premiseHazard.ccCustomer.localeCompare(this.ccCustomer, undefined, { sensitivity: 'accent' }) === 0;
    const agencyEquals: boolean = !this.ccAgency || premiseHazard.ccAgency.localeCompare(this.ccAgency, undefined, { sensitivity: 'accent' }) === 0;
    this.isSharedHazard = !(customerEquals && agencyEquals);
    this.isSharedHazardEvent.emit(this.isSharedHazard);
  }

  addAttachmentClicked(): void {
    this.attachmentForm = new FormGroup({
      name: new FormControl<string>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
      description: new FormControl<string>(null, [Validators.maxLength(1024)]),
      filePath: new FormControl<string>(null, [Validators.required, Validators.minLength(1)]),
    });
    const uploadModal = this.modalService.open(this.attachmentModal, {
      hasBackdrop: true,
      disableClose: true,
      size: 'medium',
    });

    uploadModal
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          if (!this.attachmentForm.valid || !this.selectedFile) {
            return;
          }
          this.uploadFile();
        }
        else {
          this.toastService.warning('Attachment upload cancelled', null, {
            autoDismiss: true,
          });
        }
      });
  }

  deleteAttachmentClicked(attachment: Attachment): void {
    const modal = this.modalService.open(this.deleteAttachmentModal, {
      hasBackdrop: true,
      disableClose: true,
      size: 'medium',
    });

    modal.afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const loadingModal = this.modalService.open(this.loadingModal, {
            disableClose: true,
            hasBackdrop: true,
          });
          this.requestProccessing.emit();
          this.commandService.deleteAttachment(this.PremiseHazard.id, attachment.id)
            .pipe(take(1))
            .subscribe(result => {
              this.requestCompleted.emit();
              this.premiseHazardModifiedEvent.emit();
              loadingModal.close();
            });
        }
      });
  }

  private uploadFile(): void {
    const loadingModal = this.modalService.open(this.loadingModal, {
      disableClose: true,
      hasBackdrop: true,
      size: 'medium',
    });
    this.requestProccessing.emit();
    const attachment: AttachmentInputModel = {
      description: this.attachmentForm.controls['description'].value,
      name: this.attachmentForm.controls['name'].value,
      file: this.selectedFile,
    };

    this.commandService
      .uploadAttachment(this.PremiseHazard.id, attachment)
      .pipe(take(1),
        catchError((err) => {
          this.toastService.error("Attachment upload failed", null, {
            autoDismiss: 20000,
          });
          loadingModal.close();
          return throwError(() => err);
        }))
      .subscribe((response) => {
        this.toastService.success('Attachment uploaded successfully', null, {
          autoDismiss: true,
        });
        this.premiseHazardModifiedEvent.emit();
        this.requestCompleted.emit();
        loadingModal.close();
      });
  }

  public onFileChanged(event: any): void {
    if (event.target.files[0].size >= 104857600) {
      this.attachmentForm.controls['filePath'].setErrors({
        'maxSize': true
      });
    } else {
      this.attachmentForm.controls['filePath'].setErrors(null);
      this.selectedFile = event.target.files[0];
    }
  }

  public onViewAttachmentClicked(attachment: Attachment): void {
    const loadingModal = this.modalService.open(this.loadingModal, {
      disableClose: true,
      hasBackdrop: true,
      size: 'medium',
    });

    this.requestProccessing.emit();

    this.queryService.getAttachment(this.PremiseHazard.id, attachment.id)
      .pipe(take(1), catchError(err => {
        loadingModal.close();
        this.requestCompleted.emit();

        return of();
      }))
      .subscribe(response => {
        loadingModal.close();
        this.requestCompleted.emit();
        window.open(response.uri_inline, '_blank');
      });
  }
}
