import { ChangeDetectionStrategy, Component, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PremiseHazardType } from '../../premise-hazards/premise-hazard-type.model';
import { PremiseHazard } from '../../premise-hazards/premise-hazard.model';
import { Subject, take } from 'rxjs';
import { ModalService } from '@msi/cobalt';
import { PremiseHazardQueryRestService } from '../../services/premise-hazard-rest.service';
import { PremiseHazardCommandsRestService } from '../../services/premise-hazard-command-rest.service';
import { Units } from '../../premise-hazards/units.model';
import { ResponseItem } from '../../services/response-item.model';

@Component({
  selector: 'app-premise-hazard-type-page',
  templateUrl: './premise-hazard-type-page.component.html',
  styleUrls: ['./premise-hazard-type-page.component.scss'],
  providers: [ModalService],
})
export class PremiseHazardTypePageComponent implements OnInit, OnDestroy, OnChanges {
  SelectedPremiseHazardType: PremiseHazardType;
  SelectedPremiseHazardTypeEtag: string;
  IsViewing: boolean = true;
  IsEditing: boolean = false;
  AllowInteraction: boolean = false;
  SelectedUnits: Units = Units.Foot;

  changePremiseHazardTypeViewSubject: Subject<PremiseHazardType> = new Subject<PremiseHazardType>();
  premiseHazardTypeDeletedSubject: Subject<PremiseHazardType> = new Subject<PremiseHazardType>();
  refreshListSubject: Subject<void> = new Subject<void>();

  constructor(
    private restService: PremiseHazardQueryRestService,
    private commandService: PremiseHazardCommandsRestService,
    private modalService: ModalService) { }

  openModal(templateRef: TemplateRef<boolean | null>): void {
    const modalRef = this.modalService.open(templateRef, {
      disableClose: true,
      hasBackdrop: true,
    });

    modalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.deletePremiseHazardType();
        }
      });
  }

  ngOnChanges(): void {
    if (this.SelectedPremiseHazardType) {
      this.restService.getPremiseHazardType(this.SelectedPremiseHazardType.id)
        .pipe(take(1))
        .subscribe(response => {
          this.SelectedPremiseHazardType = response.Item;
          this.SelectedPremiseHazardTypeEtag = response.ETag;
        });
    }
  }

  ngOnDestroy(): void { }

  ngOnInit(): void {
    this.AllowInteraction = true;
  }

  public onPremiseHazardTypeSelected(premiseHazardType: PremiseHazardType): void {
    if (this.IsViewing) {
      this.SelectedPremiseHazardType = premiseHazardType;
    }
  }

  public addNewPremiseHazardTypeClicked(): void {
    this.IsViewing = false;
    this.IsEditing = true;
    this.SelectedPremiseHazardType = null;
  }

  public onEditClicked(): void {
    if (!this.SelectedPremiseHazardType) {
      return;
    }
    this.AllowInteraction = false;
    this.restService.getPremiseHazardType(this.SelectedPremiseHazardType.id)
      .pipe(take(1))
      .subscribe(response => {
        if (response.Item) {
          this.SelectedPremiseHazardType = response.Item;
          this.SelectedPremiseHazardTypeEtag = response.ETag;
          this.IsViewing = false;
          this.IsEditing = true;
          this.AllowInteraction = true;
        }
      });
  }

  public refreshClicked(): void {
    this.refreshListSubject.next();
  }

  private deletePremiseHazardType(): void {
    if (!this.SelectedPremiseHazardType) {
      return;
    }
    this.AllowInteraction = false;
    this.commandService.deletePremiseHazardType(this.SelectedPremiseHazardType.id, this.SelectedPremiseHazardTypeEtag)
      .pipe(take(1))
      .subscribe(response => {
        if (response.Item) {
          this.premiseHazardTypeDeletedSubject.next(response.Item);
        }
        this.AllowInteraction = true;
      });
  }

  public onSubmitEvent(response: ResponseItem<PremiseHazardType>): void {
    if (response) {
      this.SelectedPremiseHazardType = response.Item;
    }
    this.IsViewing = true;
    this.IsEditing = false;
    this.changePremiseHazardTypeViewSubject.next(response.Item);
    this.SelectedPremiseHazardTypeEtag = response.ETag;
  }

  public onCancelEvent(): void {
    this.IsViewing = true;
    this.IsEditing = false;
    this.changePremiseHazardTypeViewSubject.next(null);
  }
}
