import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DataTableComponent } from "@msi/cobalt";
import { Observable, ReplaySubject, Subscription, map, take, tap } from "rxjs";
import { GetPremiseHazardsResponse } from "../../services/get-premise-hazards-response.model";
import { PremiseHazardQueryRestService } from "../../services/premise-hazard-rest.service";
import { ResponseItem } from "../../services/response-item.model";
import { GetPremiseHazardsRequestModel } from "../get-premisehazards-request.model";
import { IncludeAttachmentType } from "../include-attachment-type.model";
import { PremiseHazardType } from "../premise-hazard-type.model";
import { PremiseHazard } from "../premise-hazard.model";
@Component({
  selector: 'app-premise-hazard-list',
  templateUrl: './premise-hazard-list.component.html',
  styleUrls: ['./premise-hazard-list.component.scss']
})

export class PremiseHazardListComponent implements OnInit, OnDestroy, OnChanges {
  @Output() PremiseHazardClicked: EventEmitter<PremiseHazard> = new EventEmitter<PremiseHazard>();
  @Output() AddNewPremiseHazardClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() PremiseHazardTypes: PremiseHazardType[];
  @Input() changeSelectedPremiseHazard: Observable<ResponseItem<PremiseHazard>>;
  @Input() premiseHazardDeletedSubject: Observable<PremiseHazard>;
  @Input() refreshList: Observable<void>;
  @Input() inEditMode: boolean = false; 

  @ViewChild('table') table!: DataTableComponent;

  private changeSelectedPremiseHazardSubscription: Subscription;
  private premiseHazardDeletedSubjectSubscription: Subscription;
  private refreshListSubscription: Subscription;
  private premiseHazardsDictionary: Map<string, PremiseHazard> = new Map<string, PremiseHazard>();
  private premiseHazards: PremiseHazard[] = new Array<PremiseHazard>();
  private premiseHazardsSubject = new ReplaySubject<PremiseHazard[]>();
  PremiseHazards$: Observable<PremiseHazard[]> = this.premiseHazardsSubject.pipe(map(response => response));
  SelectedPremiseHazard: PremiseHazard = null;
  private continuationToken: string = null;
  ItemsPerPageValues: number[] = [10, 25, 50, 100];
  ItemsPerPage: number = this.ItemsPerPageValues[0];
  HasMoreResults: boolean = false;
  AllowInteraction: boolean = false;
  IsInitialized: boolean = false;
  SelectionIndex: number | null;
  constructor(private queryService: PremiseHazardQueryRestService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.changeSelectedPremiseHazardSubscription.unsubscribe();
    this.premiseHazardsSubject.unsubscribe();
    this.premiseHazardDeletedSubjectSubscription.unsubscribe();
    this.refreshListSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.changeSelectedPremiseHazardSubscription = this.changeSelectedPremiseHazard.subscribe(hazardIn => {
      this.onChangeSelectedPremiseHazard(hazardIn);
    });
    this.premiseHazardDeletedSubjectSubscription = this.premiseHazardDeletedSubject.subscribe(hazard => {
      this.onPremiseHazardDeleted(hazard);
    });
    this.refreshListSubscription = this.refreshList.subscribe(() => {
      this.onRefreshListClicked();
    });
    this.loadPremiseHazards().pipe(take(1)).subscribe(response => {
      for (let premiseHazard of response.premiseHazards) {
        this.premiseHazardsDictionary.set(premiseHazard.id, premiseHazard);
      }
      this.premiseHazards.push(...response.premiseHazards);
      this.premiseHazardsSubject.next(this.premiseHazards);
      this.continuationToken = response.continuationToken;
      this.AllowInteraction = true;
      this.IsInitialized = true;
      this.HasMoreResults = (this.continuationToken !== null);
      if (this.premiseHazards.length !== 0) {
        this.premiseHazardClicked({ index: 0 });
      }
    });
  }

  private onPremiseHazardDeleted(premiseHazard: PremiseHazard): void {
    if (!this.premiseHazardsDictionary.has(premiseHazard.id)) {
      return;
    }
    let idx = 0;
    for (const [key, _] of this.premiseHazardsDictionary) {
      if (key === premiseHazard.id) {
        this.premiseHazards.splice(idx, 1);
        this.premiseHazardsDictionary.delete(premiseHazard.id);
        this.premiseHazardsSubject.next(this.premiseHazards);
        break;
      }
      idx++;
    }
    if (this.premiseHazards.length !== 0) {
      this.premiseHazardClicked({ index: 0 });
    }
  }

  private onRefreshListClicked(): void {
    this.AllowInteraction = false;
    if (this.SelectedPremiseHazard) {
      this.SelectedPremiseHazard = null;
    }
    this.premiseHazards = new Array<PremiseHazard>();
    this.premiseHazardsSubject.next(this.premiseHazards);
    this.premiseHazardsDictionary = new Map<string, PremiseHazard>();
    this.continuationToken = null;
    this.HasMoreResults = false;
    this.loadPremiseHazards().pipe(take(1)).subscribe(response => {
      for (let premiseHazard of response.premiseHazards) {
        this.premiseHazardsDictionary.set(premiseHazard.id, premiseHazard);
      }
      this.premiseHazards.push(...response.premiseHazards);
      this.premiseHazardsSubject.next(this.premiseHazards);
      this.continuationToken = response.continuationToken;
      this.AllowInteraction = true;
      this.IsInitialized = true;
      this.HasMoreResults = (this.continuationToken !== null);
    });
  }

  private onChangeSelectedPremiseHazard(premiseHazard: ResponseItem<PremiseHazard>): void {
    if (!premiseHazard) {
      this.premiseHazardClicked({ index: this.SelectionIndex })
      return;
    }
    this.AllowInteraction = false;
    this.queryService.getPremiseHazard(premiseHazard.Item.id, {
      includeAttachments: IncludeAttachmentType.none,
      includeExtensionData: false,
      includeSupportingDocuments: true,
    }).pipe(take(1), tap(() => this.AllowInteraction = true)).subscribe(response => {
      if (!this.premiseHazardsDictionary.has(response.Item.id)) {
        this.premiseHazardsDictionary.set(response.Item.id, response.Item);
        this.premiseHazards.push(response.Item);
        this.premiseHazardsSubject.next(this.premiseHazards);
        this.premiseHazardClicked({ index: this.premiseHazards.length - 1 });
      } else {
        let idx = 0;
        for (const [key, _] of this.premiseHazardsDictionary) {
          if (key === response.Item.id) {
            this.AllowInteraction = false;
            this.AllowInteraction = true;
            this.premiseHazards[idx] = response.Item;
            this.premiseHazardsSubject.next(this.premiseHazards);
            this.premiseHazardClicked({ index: idx });
            break;
          }
          idx++;
        }
      }
    });
    
  }

  private loadPremiseHazards(): Observable<GetPremiseHazardsResponse> {
    this.AllowInteraction = false;
    let request: GetPremiseHazardsRequestModel = {
      includeAttachments: IncludeAttachmentType.none,
      continuationToken: this.continuationToken,
      includeSharedData: true,
      includeSupportingDocuments: true,
      listOnly: false,
      maxItemCount: this.ItemsPerPage,
      priority: null,
      returnExtensionData: false
    };
    return this.queryService.getPremiseHazards(request);
  }

  public premiseHazardClicked(event: any) {
    let index = event.index;

    if (index < 0) {
      return;
    }
    this.SelectionIndex = index;
    this.PremiseHazardClicked.emit(this.premiseHazards[index]);
  }

  public loadNextPageClicked() {
    if (!this.HasMoreResults) {
      return;
    }
    this.loadPremiseHazards().pipe(take(1), tap(() => this.AllowInteraction = true)).subscribe(response => {
      let newHazards = new Array<PremiseHazard>();
      for (let premiseHazard of response.premiseHazards) {
        // Created items are immediately added to the list, so by storing their ID we can prevent duplicates from showing up in the list
        if (!this.premiseHazardsDictionary.has(premiseHazard.id)) {
          this.premiseHazardsDictionary.set(premiseHazard.id, premiseHazard);
          newHazards.push(premiseHazard);
        }
      }
      this.premiseHazards.push(...newHazards);
      this.premiseHazardsSubject.next(this.premiseHazards);
      this.continuationToken = response.continuationToken;
      this.AllowInteraction = true;
      this.IsInitialized = true;
      this.HasMoreResults = (this.continuationToken !== null);
    });
  }

  public setItemsPerPage(index: number) {
    if (index < 0 || index >= this.ItemsPerPageValues.length) {
      return;
    }
    this.ItemsPerPage = this.ItemsPerPageValues[index];
  }

  public onItemsPerPageChanged(value: string) {
    this.ItemsPerPageValues.forEach((element, index) => {
      if (element === parseInt(value)) {
        this.setItemsPerPage(index);
      }
    });
  }
}
