import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvService } from './env.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Guid } from 'guid-typescript';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private http: HttpClient, private envService: EnvService) { }
  private CUSTOMER_ID = sessionStorage.getItem('customer');
  private AGENCY_ID = sessionStorage.getItem('agency');
  private Customer = new BehaviorSubject<string>(this.CUSTOMER_ID);
  private Agency = new BehaviorSubject<string>(this.AGENCY_ID);
  private UserReady = new BehaviorSubject<boolean>(false);
  private isSuperAdmin = new BehaviorSubject<boolean>(false);
  private defaultDistanceUnit = new BehaviorSubject<string>('Foot');
  private MSIProduct = 'PHZ' // need to be passed while calling Verify/Geocoding Endpoints
  private ForStorage = true; // need to be passed while calling Verify/Geocoding Endpoints
  private CCAdminVersion = this.envService.admin;
  // for sharing agency infomation between different components
  currentAgency = this.Agency.asObservable();
  currCustomer = this.Customer.asObservable();
  Ready = this.UserReady.asObservable();
  isCCAdminSuperAdmin = this.isSuperAdmin.asObservable();
  DefaultDistanceUnit = this.defaultDistanceUnit.asObservable();
  env1 = this.envService.env1;
  env = this.envService.env;
  env2 = this.envService.env2;
  domainExtension = this.envService.domainExtension;
  // tslint:disable-next-line:variable-name
  Jwthelper = new JwtHelperService(); // use for checking the access_token is expired or not
  adminBaseUrl =`https://coregis${this.env1}commandcentral.${this.domainExtension}/admin/v1/` //'http://localhost:5000/admin/v1/'; // for local testing
  phzBaseUrl = `https://coregis${this.env1}commandcentral.com/premisehazards/v2/`;//`https://localhost:5414/premisehazards/v2/`;
  phzBaseUrlSync = `https://coregis${this.env1}commandcentral.com/sync/premisehazards/v2/`;
  phzBaseUrlAdmin = `https://coregis${this.env1}commandcentral.com/admin/premisehazards/v2/`;
  getMeurl = `https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin/me`;
  getMeurl2 = `https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin-v2/me?with-relations=ALL`;
  // tslint:disable-next-line:variable-name
  geoVerificationUrl = `https://coregis${this.env1}commandcentral.${this.domainExtension}/geoverification/v1`;//`http://localhost:5004/geoverification/v1`;
  getagenciesurl = `https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin/customers`;
  convertfromMeterTo = new Map()
    .set('Foot', 3.28084)
    .set('Mile', 1609.34)
    .set('Kilometers', 1000)
    .set('Meter', 1);
  unitConvert = new Map() //from foot to another unit
    .set('Foot', 1)
    .set('Mile', 1609.34)
    .set('Kilometers', 1000)
    .set('Meter', 0.3048);
  changeAgency(item: string) {
    this.Agency.next(item);
  }
  changeCustomer(item: string) {
    this.Customer.next(item);
  }
  changeUserStatus(item: boolean) {
    this.UserReady.next(item);
  }
  CheckIfIsSuperAdmin(item: boolean) {
    this.isSuperAdmin.next(item);
  }
  changeDefaultDistanceUnit(item: string) {
    this.defaultDistanceUnit.next(item);
  }

  public getTypesProximitySchema(customerId: string = this.Customer.value, agencyId: string = this.Agency.value, continuationToken?: string) {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
        'Content-Type': 'application/json',
      }),
    };

    let url = `${this.phzBaseUrl}${customerId}/${agencyId}/types/?MaxItemCount=10&includeSharedData=true`;
    if (continuationToken) {
      url += `&ContinuationToken=${encodeURIComponent(continuationToken)}`;
    }

    return this.http.get<any>(url, opt);
  }

  public getMe() {

    if (this.CCAdminVersion === 'admin2.') {
      console.log('Calling ' + this.getMeurl2);
      return this.http.get<any>(this.getMeurl2);
    }
    else {
      console.log('Calling ' + this.getMeurl);
      return this.http.get<any>(this.getMeurl);
    }
  }

  public deleteSinglePhzRecord(schema) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
    });
    const deleteByIds = { externalIds: [schema.ExternalId] };
    return this.http.post(
      this.phzBaseUrlSync + schema.CustomerId + '/' + schema.AgencyId + '/delete', deleteByIds, { headers }
    );
  }

  upsertTypes(updateSchema, CustomerId: string = this.Customer.value, AgencyId: string = this.Agency.value) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
    });
    return this.http.post(this.phzBaseUrlSync + CustomerId + '/' + AgencyId + '/types/', updateSchema, {
      headers,
      observe: 'response',
      responseType: 'text',
    });
  };

  deleteTypes(deleteTypes: string[], CustomerId: string = this.Customer.value, AgencyId: string = this.Agency.value) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
    });

    const requestBody = {
      externalIds: deleteTypes
    };

    return this.http.post(
      `${this.phzBaseUrlSync}${CustomerId}/${AgencyId}/types/delete`,
      requestBody,
      {
        headers,
        observe: 'response',
        responseType: 'text',
      }
    );
  }


  public getPremiseHazardRecords(
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value,
    continuationToken?: string
  ) {
    let url = `${this.phzBaseUrl}${CustomerId}/${AgencyId}?includeSharedData=true`;

    // Add continuation token as a query parameter if provided
    if (continuationToken) {
      url += `&ContinuationToken=${encodeURIComponent(continuationToken)}`;
    }

    const headers = {
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-Admin-UI-${CustomerId}-${AgencyId}`,
    };

    const options = {
      headers: new HttpHeaders(headers),
    };

    return this.http.get<any>(url, options);
  }


  public updatePremiseHazardRecords(
    schema,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`,
    });

    const opt = {
      headers,
    };

    const premiseHazardObject = [
      {
        externalId: schema.ExternalId ? schema.ExternalId : Guid.create().toString(),
        externalTenantId: schema.ExternalTenantId,
        externalAgencyId: AgencyId,
        externalAddressId: schema.Address.AddressKey,
        externalHazardTypeId: schema.PremiseHazardTypeKey,
        recordTitle: schema.RecordTitle,
        ownerName: schema.OwnerName,
        ownerAddress: schema.OwnerAddress,
        ownerPhone: schema.OwnerPhone,
        comments: schema.Comments,
        authorizingPersonnel: schema.AuthorizingPersonnel,
        purgeDate: schema.PurgeDate,
        purgeType: schema.PurgeType,
        isTemporary: (schema.IsTemporary === 'true') ? true : false,
        isActive: true,
        isReviewRequired: schema.IsReviewRequired,
        lastUpdateUserAgencyId: CustomerId,
        lastUpdateUserId: schema.LastUpdateUserId,
        priority: schema.PriorityType,
      }
    ];

    return this.http.post(
      this.phzBaseUrlSync + CustomerId + '/' + AgencyId,
      premiseHazardObject,
      opt
    );
  }

  public upsertAddress(schema,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`,
    });

    var addressObject = {
      externalId: schema.AddressKey,
      externalAgencyId: AgencyId,
      commonPlace: schema.CommonPlace,
      description: schema.Description,
      unitNumber: schema.ApartmentNumber,
      buildingNumber: schema.BuildingNumber,
      apartmentNumber: schema.ApartmentNumber,
      floor: schema.Floor,
      streetPrefix: schema.StreetPrefix,
      houseAddress: schema.HouseAddress,
      streetType: schema.StreetType,
      streetSuffix: schema.StreetSuffix,
      crossStreetPrefix: schema.CrossStreetPrefix,
      crossStreetSuffix: schema.CrossStreetSuffix,
      subdivision: schema.Subdivision,
      city: schema.City,
      state: schema.State,
      postalCode: schema.PostalCode,
      extendedPostalCode: schema.ExtendedPostalCode,
      latitude: schema.Latitude,
      longitude: schema.Longitude,
      highCrossStreet: schema.HighCrossStreet,
      lowCrossStreet: schema.LowCrossStreet,
      phoneNumber: schema.PhoneNumber,
      isVerified: true,
      isActive: true,
      intersection: schema.Intersection,
      routeLatitude: schema.RouteLatitude,
      routeLongitude: schema.RouteLongitude,
    }

    console.log(addressObject);
    return this.http.post(
      this.phzBaseUrlSync + CustomerId + '/' + AgencyId + '/addresses',
      [{ ...addressObject }],
      { headers }
    );
  }

  public upsertContacts(externalPhzId, schema,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`,
    });


    const contacts = [];

    schema.forEach((contact) => {

      const devices = [];
      contact.PhoneNumber.forEach((phone) => {
        const deviceTemplate = {
          externalId: phone.PremiseHazardInfoContactPhoneKey,
          deviceType: "Phone",
          address: phone.PhoneNumber,
          isActive: true,
        };
        devices.push(deviceTemplate);
      });

      const contactTemplate = {
        externalId: contact.ContactName.PremiseHazardInfoContactKey,
        externalAgencyId: AgencyId,
        externalTenantId: CustomerId,
        externalPremiseHazardId: externalPhzId,
        contactName: contact.ContactName.ContactName,
        isActive: true,
        devices
      };
      contacts.push(contactTemplate);
    }
    );

    return this.http.post(
      this.phzBaseUrlSync + CustomerId + '/' + AgencyId + '/contacts',
      contacts,
      { headers }
    );
  }

  public deleteContact(
    externalPhzId: string,
    schema: string[] = [],
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`,
    });
    const items: any[] = []
    schema.forEach((contactId) => {
      items.push({
        externalId: contactId,
        externalPremiseHazardId: externalPhzId,
      })
    });

    return this.http.post(
      this.phzBaseUrlSync + CustomerId + '/' + AgencyId + '/contacts/delete',
      { items },
      { headers }
    );
  }

  // call geoverfication API
  public callGeoverfication(
    locationinformation: any,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    locationinformation.ForStorage = this.ForStorage;
    locationinformation.MSIProduct = this.MSIProduct;
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(
      this.geoVerificationUrl + '/' + CustomerId + '/' + AgencyId + '/address/verify',
      // tslint:disable-next-line:arrow-return-shorthand
      locationinformation,
      opt
    );
  }

  // If x and y are null, geocode should be further called
  public geocode(
    locationinformation: any,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    locationinformation.ForStorage = this.ForStorage;
    locationinformation.MSIProduct = this.MSIProduct;
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'MsiProduct': `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`,
      }),
    };
    return this.http.post(
      this.geoVerificationUrl + '/' + CustomerId + '/' + AgencyId + '/address/geocode',
      locationinformation,
      opt
    );
  }


  // this is first step for uploading file
  public initiateAttachmentUpload(
    attachmentName: any,
    attachmentDescription: any,
    externalPhzId: string,
    fileContent: File,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {

    const payload = new FormData();
    payload.append('file', fileContent);
    payload.append('externalId', Guid.create().toString());
    payload.append('agencyId', AgencyId);
    payload.append('attachmentName', attachmentName);
    if (attachmentDescription !== null && attachmentDescription !== undefined) {
      payload.append('attachmentDescription', attachmentDescription);
    }

    const opt = {
      headers: new HttpHeaders({
        msiProduct: `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`
      }),
    };

    return this.http.post(`${this.phzBaseUrlSync}${CustomerId}/${AgencyId}/${externalPhzId}/attachments`, payload, opt);
  }

  public deleteAttachmentById(
    externalAttachmentId,
    externalPhzId,
    customerId: string = this.Customer.value,
    agencyId: string = this.Agency.value
  ) {
    const opt = {
      headers: new HttpHeaders({
        msiProduct: `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`
      }),
    };

    const body = {
      items: [
        {
          externalId: externalAttachmentId,
          externalPremiseHazardId: externalPhzId
        }
      ]
    };

    return this.http.post(`${this.phzBaseUrlSync}${customerId}/${agencyId}/attachments/delete`, body, opt);
  }

  // this is second step for uploading data
  public attachmentUpload(uploadFile, uploadURI, uploadHeadersName, uploadHeadersValue) {
    const opt = {
      headers: new HttpHeaders({}),
    };
    opt.headers = opt.headers.append(uploadHeadersName, uploadHeadersValue);
    console.log(opt.headers);
    return this.http.put(uploadURI, uploadFile, opt);
  }

  // complete upload
  public completeUpload(uploadPath, CustomerId: string = this.Customer.value, AgencyId: string = this.Agency.value) {
    const opt = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'MsiProduct': `PHZ-Admin-UI-${this.Customer.value}-${this.Agency.value}`,
        'Content-Type': 'application/json',
        'x-ccdrive-agency': AgencyId,
        'x-ccdrive-customer': CustomerId,
      }),
    };
    const requestbody = {
      items: [
        {
          path: uploadPath,
          version_id: null,
          md5sum: null,
        },
      ],
    };
    console.log(requestbody);
    return this.http.put(
      this.adminBaseUrl + CustomerId + '/' + AgencyId + '/Phz/record/ccdrive/complete',
      requestbody,
      opt
    );
  }

  public searchAttachmentByPath(
    fileUploadPath,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    const opt = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-ccdrive-agency': AgencyId,
        'x-ccdrive-customer': CustomerId,
      }),
    };
    const requestbody = {
      path: fileUploadPath,
      content: true,
    };
    console.log(requestbody);
    console.log(opt);
    return this.http.post(
      this.phzBaseUrl + CustomerId + '/' + AgencyId + '/Phz/record/ccdrive/search',
      requestbody,
      opt
    );
  }

  public getAttachmentById(
    premiseHazardId,
    attachmentId,
    customerId: string = this.Customer.value,
    agencyId: string = this.Agency.value
  ) {
    const opt = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'MsiProduct': `PHZ-Admin-UI-${customerId}-${agencyId}`,
      }),
    };

    return this.http.get(`${this.phzBaseUrl}${customerId}/${agencyId}/premiseHazards/${premiseHazardId}/attachments/${attachmentId}`, opt);
  }

  public CreatePHZAttachmentRecord(
    newAttachment,
    CustomerId: string = this.Customer.value,
    AgencyId: string = this.Agency.value
  ) {
    {
      const opt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.post(
        this.phzBaseUrl + CustomerId + '/' + AgencyId + '/Phz/record/attachment',
        newAttachment,
        opt
      );
    }
  }

  public getallagencies(CustomerId: string = this.Customer.value) {
    return this.http.get(this.getagenciesurl + '/' + CustomerId + '/agencies?compact=false&offset=0&limit=-1&order=id');
  }

  public getcutomerKey(CustomerId: string = this.Customer.value, AgencyId: string = this.Agency.value) {
    return this.http.get(this.phzBaseUrl + CustomerId + '/' + AgencyId + '/Phz/');
  }

  public getCustomerList() {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(this.adminBaseUrl + 'TenantId/' + 'GetCustomerIDs/PremiseHazards', opt);
  }

  public GetSharingCustomers(customerId: string = this.Customer.value, agencyId: string = this.Agency.value) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
    });

    return this.http.get(
      this.phzBaseUrlAdmin + customerId + '/' + agencyId + '/sharing', { headers, observe: 'response' }
    );
  }

  public UpsertSharingCustomers(request, etag: string, customerId: string = this.Customer.value, agencyId: string = this.Agency.value) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
      'ETag': etag
    });

    return this.http.put(
      this.phzBaseUrlAdmin + customerId + '/' + agencyId + '/sharing', request, { headers, observe: 'response' }
    );
  }

  public getTenantId(customerId: string = this.Customer.value) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',        
        'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
      }),
      responseType: 'text' as 'json',
    };
    return this.http.get(`${this.adminBaseUrl}TenantId/${customerId}/GetCustomerTenantId/PremiseHazards`, opt);
  }

  public getPremiseHazardTenantInfo(tenantId: string) {
    const opt = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'MsiProduct': `PHZ-UI-${this.Customer.value}-${this.Agency.value}`,
      }),
    };
    return this.http.get(`${this.adminBaseUrl}Phz/tenant/${tenantId}`, opt);
  }

  exportData(type: 'type' | 'record'): Observable<void> {
    const endpoint = this.adminBaseUrl + this.Customer.value + '/' + this.Agency.value + `/Phz/${type}/export`;
    const opt = { responseType: 'blob' as const };
    // tslint:disable-next-line:deprecation
    return this.http.get(endpoint, opt).pipe(
      map((res) => {
        const fileName = `${this.Agency.value}-phz-${type}-export.xlsx`;
        saveAs(res, fileName);
      })
    );
  }

  importData(type: 'type' | 'record', file: File): Observable<ImportResults> {
    const endpoint = this.adminBaseUrl + this.Customer.value + '/' + this.Agency.value + `/Phz/${type}/import`;
    const formData = new FormData();
    formData.append('File', file, file.name);
    const opt = { responseType: 'json' as const };
    return this.http.post(endpoint, formData, opt).pipe(map((res) => res as ImportResults));
  }
}

export type ImportResults = {
  globalErrors: Array<string>;
  invalidRows: Array<InvalidRow>;
  ignoredRows: Array<IgnoredRow>;
};

export type InvalidRow = {
  sheetName: string;
  rowNumber: number;
  validationFailures: Array<ValidationFailure>;
};

export type IgnoredRow = {
  sheetName: string;
  rowNumber: number;
  error: string;
};

export type ValidationFailure = {
  propertyName: string;
  errorMessage: string;
  attemptedValue: any;
};
