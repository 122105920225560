<div class="row page-container">
    <div class="col-4 view-container">
        <div class="button-header">
            <button msiTooltip="Add a new premise hazard record" class="msi-btn msi-btn-text-and-icon" (click)="addNewPremiseHazardClicked()" [disabled]="!AllowInteraction || IsEditing"><msi-icon name="ic_add" />Add Premise Hazard</button>
            <button msiTooltip="Clear all results and refresh the list" class="msi-btn msi-btn-text-and-icon" (click)="refreshClicked()" [disabled]="!AllowInteraction || IsEditing"><msi-icon name="ic_refresh" />Refresh list</button>
        </div>
        <div class="control-container">
            <app-premise-hazard-list (PremiseHazardClicked)="onPremiseHazardSelected($event)" [PremiseHazardTypes]="PremiseHazardTypes" [changeSelectedPremiseHazard]="changePremiseHazardViewSubject" [premiseHazardDeletedSubject]="premiseHazardDeletedSubject" [refreshList]="refreshListSubject" [inEditMode]="IsEditing"></app-premise-hazard-list>
        </div>
    </div>
    <div class="col-8 view-container">
        <div class="button-header">
            <button msiTooltip="{{ SelectedPremiseHazard ? 'Edit this premise hazard' : 'Select a premise hazard to edit'}}" class="msi-btn msi-btn-text-and-icon" (click)="onEditClicked()" [disabled]="!AllowInteraction || !SelectedPremiseHazard || !IsViewing || isSharedHazard"><msi-icon name="ic_edit" />Edit Premise Hazard</button>
            <button msiTooltip="{{ SelectedPremiseHazard ? 'Delete this premise hazard' : 'Select a premise hazard to delete'}}" class="msi-btn msi-btn-text-and-icon" (click)="openModal(deleteModal)" [disabled]="!AllowInteraction || !SelectedPremiseHazard || isSharedHazard"><msi-icon name="ic_trash" />Delete Premise Hazard</button>
        </div>
        <div class="control-container">
            <app-view-premise-hazard-item *ngIf="IsViewing" [PremiseHazard]="SelectedPremiseHazard" (premiseHazardModifiedEvent)="refreshHazard()" (requestProccessing)="onViewRequest(false)" (requestCompleted)="onViewRequest(true)" (isSharedHazardEvent)="setSharedHazard($event)"></app-view-premise-hazard-item>
            <app-premise-hazard-item (submittedEvent)="onSubmitEvent($event)" (cancelEvent)="onCancelEvent()" *ngIf="IsEditing" [SelectedPremiseHazard]="SelectedPremiseHazard" [ETag]="SelectedPremiseHazardEtag" [PremiseHazardTypes]="PremiseHazardTypes" [Addresses]="Addresses"></app-premise-hazard-item>
        </div>
    </div>
</div>

<ng-template #deleteModal let-data let-ref="modalRef">
    <msi-modal>
        <msi-modal-header class="msi-padding-s">
            <label class="msi-label">Delete Premise Hazard</label>
        </msi-modal-header>
        <msi-modal-content class="msi-padding-m">
            <p>Confirm deletion of {{SelectedPremiseHazard.recordTitle}}<br/>
                This action can not be reversed</p>
        </msi-modal-content>
        <msi-modal-actions align="center" class="msi-padding-s">
            <button class="msi-btn msi-btn-primary msi-btn-text-and-icon" (click)="ref.close(false)">
                <msi-icon name="ic_remove" class="delete-icon"/>Cancel
            </button>
            <button class="msi-btn msi-btn-secondary msi-btn-text-and-icon" (click)="ref.close(true)">
                <msi-icon name="ic_trash" color="#cc0000"/>Delete
            </button>
        </msi-modal-actions>
    </msi-modal>
</ng-template>
