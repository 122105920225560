import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { TokenService } from './services/token.service';
import { RestService } from './services/rest.service';
import { FormControl } from '@angular/forms';
import { ToastService } from '@msi/cobalt';
import { Subscription } from 'rxjs';
import { EnvService } from './services/env.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderTokenService } from '@msi/commandcentral-user-authentication';
import { take } from 'rxjs/operators';
import { ThemeSwitcherService } from './theme-switcher.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'gis-prh-ui';
  user;
  clientId;
  customer;
  agency;
  scopes;
  customerId;
  roleSystemName;
  groupSystemName;
  agencyId;
  token;
  admin;
  cloud;
  isParentAgencyAdminUser = false;
  currentAgencyRoot: FormControl;
  agencyList = [];
  GetMeLoader: Subscription;
  GetMeDone = false;

  @Input() theme!: string;
  ShowThemeToggle: boolean = true;
  // agencyList = ['msi.test.phz', 'pd.msi.test.phz', 'fire.msi.test.phz'];
  constructor(
    private rest: RestService,
    private toastService: ToastService,
    private headerTokenService: HeaderTokenService,
    private route: ActivatedRoute,
    private storedToken: TokenService,
    private env: EnvService,
    private themeSwitcherService: ThemeSwitcherService,
    private cdr: ChangeDetectorRef
  ) {}
  private env1 = this.env.env1;
  private CCAdminVersion = this.env.admin;
  private THEME_TEXTS: string[] = ['Light Mode', 'Dark Mode'];
  lightMode: boolean = false;
  ThemeText: string = this.THEME_TEXTS[this.lightMode ? 0 : 1];
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.customerId = params.customerId;
      this.roleSystemName = params.roleSystemName;
      this.groupSystemName = params.groupSystemName;
      this.agencyId = params.agencyId;
      this.admin = params.admin;
      this.cloud = params.cloud;
      sessionStorage.setItem('cloud', this.cloud);
      console.log('app comp admin ' + this.admin);
      console.log('app comp CCadmin ' + this.CCAdminVersion);
      this.env.SetAdmin(this.admin);
    });

    // If no theme param is present, we just stick to default beahvior
    if (this.theme) {
      this.ShowThemeToggle = false;
      this.lightMode = this.theme === 'LIGHT';
      this.themeSwitcherService.toggleTheLights(this.lightMode);
      this.cdr.detectChanges();
    }

    // to get tokenReady value, confirmAuthGuardService must be added to routes, see app-routing.module.ts
    this.headerTokenService.tokenReady.pipe(take(1)).subscribe((isTokenReady) => {
      if (isTokenReady) {
        const phzName ='phzProvisioning';
        const texttoken = this.headerTokenService.getTokenSync();
        this.token = texttoken;
        this.storedToken.setToken(this.token);
        const token = this.encodeToken(texttoken);
        this.user = token.sub;
        sessionStorage.setItem('user', this.user);
        this.clientId = token.client_id;
        this.customer = token.customer;
        sessionStorage.setItem('customer', this.customer);
        this.agency = token.agency;
        sessionStorage.setItem('agency', this.agency);
        this.scopes = token.scope;
        // call getme
        this.GetMeLoader = this.rest
          .getMe()
          .pipe(take(1))
          .subscribe(
            (data) => {
              if (this.GetMeDone === false) {
                console.log(data);
                this.GetMeDone = true;
                let userAgency = '';
                let userCustomer = '';

                if(this.CCAdminVersion === 'admin2.')
                {
                  console.log('Admin2 here');
                  let val = sessionStorage.getItem('customer');
                  if (data.me.currentAgency !== undefined)
                  {
                    val = data.me.currentAgency;
                  }

                    this.rest.changeAgency(val);
                    this.rest.changeCustomer(val);
                    this.rest.CheckIfIsSuperAdmin(true);
                    userAgency = val;
                    userCustomer = val;
                }
                else if (data.impersonatingUser !== undefined)
                {
                    console.log('Admin1 here');
                    this.rest.changeAgency(data._links.agency.agencyId);
                    this.rest.changeCustomer(data._links.customer.customerId);
                    this.rest.CheckIfIsSuperAdmin(true);
                    userAgency = data._links.agency.agencyId;
                    userCustomer = data._links.customer.customerId;
                } else {
                  console.log('Neither here');
                  this.rest.CheckIfIsSuperAdmin(false);
                  this.rest.changeAgency(this.agency);
                  this.rest.changeCustomer(this.customer);
                  userAgency = this.agency;
                  userCustomer = this.customer;
                }
                console.log('Agency: ' + userAgency);
                console.log('Customer: ' + userCustomer);
                this.rest.changeUserStatus(true);

       
                if(this.CCAdminVersion === 'admin2.')
                {
                  console.log('inside admin2 for permissions check');
                  for (const permission of data.me.permissions) {
                    if (permission.serviceSystemName === 'ProvisionSubAgencies' && userCustomer === userAgency) {
                      this.rest
                      .getallagencies(userCustomer)
                      .toPromise()
                      .then(
                        (response) => {
                          console.log(response);
                          const res = response;
                          const agencyList = (res as any)._embedded.item;
                          for (const agency of agencyList) {
                            this.agencyList.push(agency.id);
                          }
                          console.log(agencyList);
                          this.isParentAgencyAdminUser = true;
                          this.currentAgencyRoot = new FormControl(userAgency);
                          this.onChanges();
                        },
                        (err) => {
                          this.toastService.error('Fail to get agencies of this customer - ' + err.status, null, {
                            autoDismiss: 10000,
                            closeButton: true,
                          });
                          console.log(err);
                        }
                      );
                    break;
                    }
                  };
                }
                else
                {
                for (const permission of data.services[phzName].permissions) {
                  if (permission.systemName === 'ProvisionSubAgencies' && userCustomer === userAgency) {
                    this.rest
                      .getallagencies(userCustomer)
                      .toPromise()
                      .then(
                        (response) => {
                          console.log(response);
                          const res = response;
                          const agencyList = (res as any)._embedded.item;
                          for (const agency of agencyList) {
                            this.agencyList.push(agency.id);
                          }
                          console.log(agencyList);
                          this.isParentAgencyAdminUser = true;
                          this.currentAgencyRoot = new FormControl(userAgency);
                          this.onChanges();
                        },
                        (err) => {
                          this.toastService.error('Fail to get agencies of this customer - ' + err.status, null, {
                            autoDismiss: 10000,
                            closeButton: true,
                          });
                          console.log(err);
                        }
                      );
                    break;
                  }
                }
              }
              }
            },
            (err) => {
              console.log(err);
            }
          );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.GetMeLoader !== undefined) {
      this.GetMeLoader.unsubscribe();
    }
  }
  onChanges(): void {
    this.currentAgencyRoot.valueChanges.subscribe((val) => {
      console.log('Current Agency:' + this.currentAgencyRoot.value);
      this.rest.changeAgency(this.currentAgencyRoot.value);
    });
  }

  public markTabAsActive(tabId: string) {
    document.querySelectorAll('.nav-item').forEach(tab => {
      tab.classList.remove('active');
    });
       
    document.getElementById(tabId).classList.add('active');
  }

  private encodeToken(token: string) {
    const payloadBase64 = token.split('.')[1];
    const payloadStr = atob(payloadBase64);
    return JSON.parse(payloadStr);
  }

  public onThemeToggle(event: any): void {
    this.lightMode = !this.lightMode;
    if (this.lightMode) {
      this.ThemeText = this.THEME_TEXTS[0];
    } else {
      this.ThemeText = this.THEME_TEXTS[1];
    }
    this.themeSwitcherService.toggleTheLights(this.lightMode);
  }
}
