<div class="container-fluid light" style="overflow-y: hidden;">
    <div class="row" style="display: flex; flex-direction: row;">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <msi-sidenav class="sidenav" #sidenav role="navigation" [collapsable]="true">
                <msi-item routerLinkActive="selected" routerLink="/hazards">Hazards</msi-item>
                <msi-item routerLinkActive="selected" routerLink="/types">Hazard Types</msi-item>
                <msi-toggle [checked]="!lightMode" (change)="onThemeToggle($event)" [text]="ThemeText" [hidden]="!ShowThemeToggle"></msi-toggle>
            </msi-sidenav>
        </div>
        <div class="col-sm-10 col-md-10 col-lg-10 col-lg-10">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
