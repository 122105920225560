import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from "@angular/core";
import { ModalService } from "@msi/cobalt";
import { take, tap } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { PremiseHazardQueryRestService } from "../../services/premise-hazard-rest.service";
import { Address } from "../address.model";
import { GetPremiseHazardRequestModel } from "../get-premise-hazard-request.model";
import { IncludeAttachmentType } from "../include-attachment-type.model";
import { PremiseHazardType } from "../premise-hazard-type.model";
import { PremiseHazard } from "../premise-hazard.model";
import { ResponseItem } from "../../services/response-item.model";
import { GetPremiseHazardTypesRequestModel } from "../get-premise-hazard-type-request.model";
import { GetAddressesRequestModel } from "../get-addresses-request.model";
import { PremiseHazardCommandsRestService } from "../../services/premise-hazard-command-rest.service";

@Component({
  selector: 'app-premise-hazard-page',
  templateUrl: './premise-hazard-page.component.html',
  styleUrls: ['./premise-hazard-page.component.scss'],
  providers: [ModalService],
})

export class PremiseHazardsPageComponent implements OnInit, OnDestroy, OnChanges {
  SelectedPremiseHazard: PremiseHazard;
  SelectedPremiseHazardEtag: string;
  IsViewing: boolean = true;
  IsEditing: boolean = false;
  AllowInteraction: boolean = false;
  PremiseHazardTypes: PremiseHazardType[] = new Array<PremiseHazardType>();
  Addresses: Address[] = new Array<Address>();

  private typeContinuationToken: string = null;
  private addressContinuationToken: string = null;
  private typesLoading: boolean = true;
  private addressesLoading: boolean = true;
  changePremiseHazardViewSubject: Subject<ResponseItem<PremiseHazard>> = new Subject<ResponseItem<PremiseHazard>>();
  premiseHazardDeletedSubject: Subject<PremiseHazard> = new Subject<PremiseHazard>();
  refreshListSubject: Subject<void> = new Subject<void>();

  public isSharedHazard: boolean = false;

  constructor(
    private queryService: PremiseHazardQueryRestService,
    private commandService: PremiseHazardCommandsRestService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef) {
  }

  openModal(templateRef: TemplateRef<boolean | null>): void {
    const modalRef = this.modalService.open(templateRef, {
      disableClose: true,
      hasBackdrop: true,
    });

    modalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.deletePremiseHazard();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.loadPremiseHazardTypes();
    this.loadAddresses();
  }

  onPremiseHazardSelected(premiseHazard: PremiseHazard): void {
    if (this.IsEditing) {

    } else {
      this.SelectedPremiseHazard = premiseHazard;
    }
  }

  loadPremiseHazardTypes(): void {
    this.typesLoading = true;
    let request: GetPremiseHazardTypesRequestModel = {
      continuationToken: this.typeContinuationToken,
      includeSharedData: false, 
      maxItemCount: 100,
    };
    this.queryService.getPremiseHazardTypes(request)
      .pipe(take(1))
      .subscribe(response => {
        this.PremiseHazardTypes = response.premiseHazardTypes;
        this.typeContinuationToken = response.continuationToken;
        if (this.typeContinuationToken) {
          this.loadPremiseHazardTypes();
        } else {
          this.typesLoading = false;
          this.setInteractionState();
        }
      });
  }

  loadAddresses(): void {
    this.addressesLoading = true;
    let request: GetAddressesRequestModel = {
      continuationToken: this.addressContinuationToken,
      includeSharedData: false,
      listOnly: true,
      maxItemCount: 100,
      returnExtensionData: false,
    };
    this.queryService.getAddresses(request)
      .pipe(take(1))
      .subscribe(response => {
        this.Addresses.push(...response.addresses);
        this.addressContinuationToken = response.continuationToken;
        if (this.addressContinuationToken) {
          this.loadAddresses();
        } else {
          this.addressesLoading = false;
          this.setInteractionState();
        }
      });
  }

  public refreshHazard(): void {
    if (!this.SelectedPremiseHazard) {
      return;
    }

    this.queryService.getPremiseHazard(this.SelectedPremiseHazard.id, {
      includeAttachments: IncludeAttachmentType.none,
      includeExtensionData: false,
      includeSupportingDocuments: true,
    })
      .pipe(take(1))
      .subscribe(response => {
        this.SelectedPremiseHazard = response.Item;
        this.AllowInteraction = false;
        this.changePremiseHazardViewSubject.next(response);
      });
  }

  public addNewPremiseHazardClicked(): void {
    this.IsViewing = false;
    this.IsEditing = true;
    this.SelectedPremiseHazard = null;
  }

  public refreshClicked(): void {
    this.refreshListSubject.next();
    this.typeContinuationToken = null;
    this.addressContinuationToken = null;
    this.loadPremiseHazardTypes();
    this.loadAddresses();
  }

  public setInteractionState(): void {
    this.AllowInteraction = !(this.typesLoading || this.addressesLoading);
  }

  public interactionEnabled(): void {
    this.AllowInteraction = true;
  }

  public interactionDisabled(): void {
    this.AllowInteraction = false;
  }

  private deletePremiseHazard(): void {
    if (!this.SelectedPremiseHazard) {
      return;
    }
    this.interactionDisabled();
    const requestModel: GetPremiseHazardRequestModel = {
      includeSupportingDocuments: true,
      includeExtensionData: true,
      includeAttachments: IncludeAttachmentType.none,
    };
    this.queryService.getPremiseHazard(this.SelectedPremiseHazard.id, requestModel)
      .pipe(take(1))
      .subscribe(response => {
        if (response.Item) {
          const id = response.Item.id;
          const etag = response.ETag;
          this.IsViewing = true;
          this.IsEditing = false;
          this.commandService.deletePremiseHazard(id, etag)
            .pipe(take(1))
            .subscribe(resp => {
              this.premiseHazardDeletedSubject.next(response.Item);
              this.interactionEnabled();
            });
        }
      });
  }

  public onEditClicked(): void {
    if (!this.SelectedPremiseHazard) {
      return;
    }
    this.interactionDisabled();
    const requestModel: GetPremiseHazardRequestModel = {
      includeSupportingDocuments: true,
      includeExtensionData: true,
      includeAttachments: IncludeAttachmentType.none,
    };
    this.queryService.getPremiseHazard(this.SelectedPremiseHazard.id, requestModel)
      .pipe(take(1))
      .subscribe(response => {
        if (response.Item) {
          this.SelectedPremiseHazard = response.Item;
          this.SelectedPremiseHazardEtag = response.ETag;
          this.IsViewing = false;
          this.IsEditing = true;
          this.interactionEnabled();
        }
      });
  }

  public onSubmitEvent(resonseItem: ResponseItem<PremiseHazard>): void {
    this.AllowInteraction = true;
    if (resonseItem) {
      this.SelectedPremiseHazard = resonseItem.Item;
    }
    this.IsViewing = true;
    this.IsEditing = false;
    this.changePremiseHazardViewSubject.next(resonseItem);
  }
  public onCancelEvent(): void {
    this.IsViewing = true;
    this.IsEditing = false;
    this.changePremiseHazardViewSubject.next(null);
  }

  public onViewRequest(isInteractionAllowed: boolean): void {
    this.AllowInteraction = isInteractionAllowed;
  }
  public setSharedHazard(event: any) {
    this.isSharedHazard = event;
    this.AllowInteraction = true;
    this.cdr.detectChanges();
  }
}
