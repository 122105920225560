import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import {MsiCommonModule, ALL_MSI_MODULES} from '@msi/cobalt';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TokenService} from './services/token.service';
import {AuthGuard} from './services/auth.guard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './interceptors/auth-interceptor.service';
import {JwtModule} from '@auth0/angular-jwt';
import {RestService} from './services/rest.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {IamAuthGrantConfigToken, UserAuthenticationModule} from '@msi/commandcentral-user-authentication';
import {getAppSpecificIamConfig} from './config/app.config';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { DisableControlDirective } from './directives/disable-control';
import { PremiseHazardQueryRestService } from './services/premise-hazard-rest.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PremiseHazardListComponent } from './premise-hazards/premise-hazard-list/premise-hazard-list.component';
import { PremiseHazardItemComponent } from './premise-hazards/premise-hazard-item/premise-hazard-item.component';
import { PremiseHazardsPageComponent } from './premise-hazards/premise-hazard-page/premise-hazard-page.component';
import { PremiseHazardViewComponent } from './premise-hazards/premise-hazard-view/premise-hazard-view.component';
import { EmptyTextPipe } from './pipes/empty-text.pipe';
import { PremiseHazardCommandsRestService } from './services/premise-hazard-command-rest.service';
import { PremiseHazardTypeItemComponent } from './premise-hazard-type/premise-hazard-type-item/premise-hazard-type-item.component';
import { PremiseHazardTypeListComponent } from './premise-hazard-type/premise-hazard-type-list/premise-hazard-type-list.component';
import { PremiseHazardTypeViewComponent } from './premise-hazard-type/premise-hazard-type-view/premise-hazard-type-view.component';
import { PremiseHazardTypePageComponent } from './premise-hazard-type/premise-hazard-type-page/premise-hazard-type-page.component';


export function tokenGetter() {
  return localStorage.getItem('access_token');
}
@NgModule({
  declarations: [
    AppComponent,
    PremiseHazardListComponent,
    PremiseHazardItemComponent,
    PremiseHazardViewComponent,
    PremiseHazardsPageComponent,
    PremiseHazardTypeItemComponent,
    PremiseHazardTypeListComponent,
    PremiseHazardTypeViewComponent,
    PremiseHazardTypePageComponent,
  ],
  imports: [
    NgxMaskDirective,
    DisableControlDirective,
    NgxMaskPipe,
    CommonModule,
    FormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    BrowserModule,
    MsiCommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UserAuthenticationModule,
    NgbModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['example.com'],
        disallowedRoutes: ['http://example.com/examplebadroute/'],
      },
    }),
    EmptyTextPipe,
  ],
  providers: [
    RestService,
    TokenService,
    AuthGuard,
    {
      provide: IamAuthGrantConfigToken,
      useFactory: getAppSpecificIamConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    provideNgxMask(),
    PremiseHazardQueryRestService,
    PremiseHazardCommandsRestService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
